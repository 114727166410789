import { HorizontalSelectItem } from './HorizontalSelectAllItems';

export const getSortedItemsAndAlphabets = (items: Array<HorizontalSelectItem>) => {
	const sortedItems = items.slice().sort((a, b) => a.title.localeCompare(b.title));
	const alphabets = Array.from(
		new Set(sortedItems.map((each) => each.title?.charAt(0).toUpperCase()))
	).filter((each) => (each?.length ?? 0) > 0);

	return { sortedItems, alphabets };
};

export const filterAlphabetItems = (alphabet?: string) => (item: HorizontalSelectItem) => {
	return item.title.toLowerCase().startsWith(alphabet?.toLowerCase() ?? '');
};
