import React, { useMemo } from 'react';
import { Box, Container, Divider } from '@mui/material';
import { useIntl } from 'react-intl';
import { Icon, Typography, ThemeTypes, Button } from 'components';
import { formatTranslation, MEDIATHEK_URLS, NextLink } from 'common';
import { HorizontalSelectAllItems } from '../../components/HorizontalSelectAllItems/HorizontalSelectAllItems';
import { makeStyles } from '@mui/styles';
import { TopicsForSelectorQuery } from '../../graphql/catalog/queries';
import { useRouter } from 'next/router';

const useStyles = makeStyles<ThemeTypes.Theme>((theme) => ({
	titleContainer: {
		marginTop: theme.spacing(6),
		display: 'flex',
		alignItems: 'center'
	},
	backIcon: {
		marginRight: theme.spacing(2),
		width: 24,
		height: 24,
		cursor: 'pointer'
	},
	title: {
		fontSize: 24,
		lineHeight: '36px',
		fontWeight: 600
	},
	divider: { marginTop: theme.spacing(3) },
	items: {
		margin: theme.spacing(4, 0)
	},
	closeContainer: {
		position: 'absolute',
		right: 10,
		height: '100%',

		'& > button': {
			position: 'sticky',
			top: 10,
			background: 'white',
			zIndex: 10
		}
	}
}));

type TopicProps = {
	topics: TopicsForSelectorQuery['topicDocuments']['data'];
	containerClassName?: string;
	onTopicClick?: (id: string) => void;
	onClose?: () => void;
	displayAllTopic?: boolean;
};

export const Topics = ({
	topics,
	containerClassName,
	onTopicClick,
	onClose,
	displayAllTopic
}: TopicProps) => {
	const { locale, formatMessage } = useIntl();
	const classes = useStyles();
	const { pathname } = useRouter();

	const ownPage = useMemo(() => pathname === MEDIATHEK_URLS.topics, [pathname]);

	const items = useMemo(() => {
		const items = topics.map((each) => ({
			id: each.code,
			onClick: onTopicClick,
			iconColor: each.iconPrimaryColor as string | undefined,
			iconUrl: each.specialityIconUrl ?? undefined,
			title: formatTranslation(each.description, { locale }) ?? '',
			href: `${ownPage ? '/' : pathname}?topic=${each.code}`
		}));
		if (displayAllTopic) {
			items.unshift({
				id: '',
				onClick: onTopicClick,
				iconColor: undefined,
				iconUrl: undefined,
				title: formatMessage({ id: 'catalog.topic-dropdown.all-topics' }),
				href: `${ownPage ? '' : pathname}`
			});
		}
		return items;
	}, [onTopicClick, topics, displayAllTopic, ownPage]);

	return (
		<Container maxWidth={ownPage && 'xl'} className={containerClassName}>
			{ownPage && (
				<>
					<Box className={classes.titleContainer}>
						<NextLink href="/">
							<Icon className={classes.backIcon} icon="arrowLeft" />
						</NextLink>
						<Typography
							className={classes.title}
							fontFamily="Poppins"
							localeId="catalog.topics.disciplines"
						/>
					</Box>
					<Divider className={classes.divider} />
				</>
			)}
			{onClose && (
				<Box className={classes.closeContainer}>
					<Button variant="outlined" onClick={onClose} type="button" title="Close">
						<Icon icon="cross" />
					</Button>
				</Box>
			)}
			<div className={classes.items}>
				<HorizontalSelectAllItems items={items} />
			</div>
		</Container>
	);
};
