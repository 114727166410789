import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';

import { booleanFilter, QueryParamsContext, useUserContext } from 'common';

import {
	TopicsForSelectorQuery,
	useTopicsForSelectorQuery,
	useTopicSortOrderQuery
} from '../../../graphql/catalog/queries';
import TopicSelectorSkeleton from './TopicSelectorSkeleton';
import PopoverTopicSelector from './PopoverTopicSelector';

export type SelectorTopic = {
	id: `${string}-${string}-${string}-${string}-${string}`;
	code: string;
	description?: unknown;
	iconPrimaryColor: string;
	specialityIconUrl?: string | null | undefined;
	displayButton?: boolean;
	abbreviation?: unknown;
};

type Props = {
	useQueryState: boolean;
	displayTopicButtons: boolean;
	displayAllTopic: boolean;
	popoverPosition?: 'start' | 'end';
	displayAbbreviation?: boolean;
	filterTopics?: (t: TopicsForSelectorQuery['topicDocuments']['data'][0]) => boolean;
	mapTopics?: (topics: Array<SelectorTopic>) => Array<SelectorTopic>;
};

export const TopicsSelector = ({
	filterTopics,
	useQueryState,
	displayTopicButtons,
	displayAllTopic,
	mapTopics,
	displayAbbreviation,
	popoverPosition = 'start'
}: Props) => {
	// we need to set the query state to keep the topic param between navigations
	const { setQuery: setQueryState, query: queryState } = useContext(QueryParamsContext);
	const { isLoggedIn } = useUserContext();
	const router = useRouter();
	const [selectedTopicState, setSelectedTopicState] = useState<SelectorTopic | undefined>(
		undefined
	);

	const { data: topicsData, loading: loadingData } = useTopicsForSelectorQuery({
		nextFetchPolicy: 'no-cache'
	});

	const { data: orderedTopicsIds, loading: loadingUserTopics } = useTopicSortOrderQuery({
		skip: !isLoggedIn,
		nextFetchPolicy: 'no-cache'
	});

	const handleTopicChange = useCallback(
		(topic: SelectorTopic) => {
			setSelectedTopicState(topic);
			const newQuery = { ...router.query, topic: topic.code };
			useQueryState && setQueryState(newQuery);
			router.push(
				{
					query: newQuery
				},
				undefined,
				{ shallow: true }
			);
		},
		[router.query, setSelectedTopicState, useQueryState]
	);

	const sortedTopics: Array<SelectorTopic> = useMemo(() => {
		let topics = topicsData?.topicDocuments.data ?? [];
		if (orderedTopicsIds?.topicDocuments.data) {
			topics = orderedTopicsIds.topicDocuments.data
				.map(({ id }) => topics.find((t) => t.id === id))
				.filter(booleanFilter);
		}
		if (filterTopics) {
			topics = topics.filter(filterTopics);
		}
		if (mapTopics) {
			topics = mapTopics(topics);
		}
		return topics;
	}, [orderedTopicsIds, topicsData, filterTopics, mapTopics]);

	const defaultTopic = useMemo(() => {
		if (!isLoggedIn) {
			return sortedTopics[0];
		}
		if (loadingUserTopics) {
			return undefined;
		}
		const speciality = orderedTopicsIds?.topicDocuments.data[0]?.id;
		if (!speciality) {
			return sortedTopics[0];
		}
		return topicsData?.topicDocuments.data.find((t) => t.id === speciality);
	}, [orderedTopicsIds, topicsData?.topicDocuments.data, isLoggedIn, sortedTopics]);

	useEffect(() => {
		const newTopicCode = (router.query.topic ||
			(useQueryState && queryState.topic) ||
			(displayTopicButtons && defaultTopic?.code)) as string;
		const foundTopic = sortedTopics.find((top) => top.code === newTopicCode);
		setSelectedTopicState((prev) => (prev?.code !== newTopicCode ? foundTopic : prev));
	}, [router.query, sortedTopics, queryState]);

	if (loadingData) {
		return <TopicSelectorSkeleton allButtons={displayTopicButtons} />;
	}

	return (
		<PopoverTopicSelector
			popoverPosition={popoverPosition}
			selectedTopicState={selectedTopicState}
			setStateTopic={setSelectedTopicState}
			sortedTopics={sortedTopics}
			displayTopicButtons={displayTopicButtons}
			handleTopicChange={handleTopicChange}
			displayAllTopic={displayAllTopic}
			displayAbbreviation={displayAbbreviation}
		/>
	);
};
